.drawer {
  position: absolute;
  top: 11vh;
  left: -5px;
  height: 40px;
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75); 
  border: 1px solid rgba(255, 255, 255, 0.125);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  .emoji {
    font-size: 24px;
    position: absolute;
    left: 8px;
    display: flex;
  }

  .text {
    padding-left: 10px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  .reported-by {
    font-size: 12px;
    color: #fff;
    font-weight: 300;
    margin-right: 4px;
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  .news-scroll-container {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
}

