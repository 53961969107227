$font-family: "Poppins", sans-serif;

.box {
  position: relative;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 48px;
  font-weight: bold;
  margin: 0 0 20px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 18px;
  }
  color: #fff;
}

.detail {
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin-bottom: 40px;
  color: #fff;
}

.grid-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  width: 80%;
  // max-width: 1200px;
  // padding: 50px;
}

.grid-item {
  position: relative;
  background-color: #8B4513;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  // gap: 15px;
  color: white;
  gap: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  align-self: center;
}

.content {
  // position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.content h3 {
  display: inline;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.content p {
  display: inline;
  margin: 0;
  font-size: 14px;
  opacity: 0.8;
}

@media (max-width: 600px) {
  .detail {
    width: 90%;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 901px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.swiper-slide-content {
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  color: #fff;

  img {
    width: 70px;
    height: 70px;
    border-radius: 10px;
  }

  h3 {
    margin: 0;
    padding: 0;
  }
}

.description-container {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 0;
    padding: 0;
  }
}
