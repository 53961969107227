.overview-title {
  text-align: center;
  font-size: 80px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 32px;
  color: #fff;
}
.overview-des {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 60px;
}
.overview-container {
  width: 1200px;
  margin: 0 auto;
  padding: 72px 40px;
  color: #ffffff;
  // border: 1px solid #fff;
  box-sizing: border-box;

  .overview-content {
    display: flex;
    gap: 60px;
    align-items: center;

    .chart-container {
      flex: 1;
      background: #212d4a;
      border-radius: 10px;
    }

    .data-container {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 64px;
      align-items: center;

      .data-item {
        border-radius: 10px;
        text-align: center;

        text-wrap: nowrap;
        .data-usdt {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 24px;
          .usdt {
            color: #bdbdbd;
          }
        }
        .label {
          font-size: 24px;
          font-weight: 700;
        }

        .value {
          font-size: 64px;
          font-weight: 400;
        }

        .wallet {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 48px;
          background: rgba(47, 47, 47, 0.38);
          padding: 10px 20px;

          .wallet-icon {
            width: 50px;
            height: 50px;
            margin-right: 8px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .wallet-address {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex: 1;
            margin-right: 16px;
          }
          p {
            margin: 0;
          }

          .wallet-copy {
            width: 39px;
            height: 39px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .overview-title {
    font-size: 32px;
    margin: 0 auto;
    margin-bottom: 24px;
  }
  .overview-des {
    font-size: 16px;
  }
  .overview-container {
    width: 100%;
    padding: unset;
    box-sizing: border-box;
    border: unset;

    .overview-content {
      flex-direction: column;
      gap: 32px;
      // background: #232d48;
      border-radius: 16px;
      padding-bottom: 40px;

      .chart-container,
      .data-container {
        width: calc(100% - 24px);
      }

      .data-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        .data-item {
          padding: 12px;
          .data-usdt {
            gap: 6px;
          }
          .label {
            font-size: 16px;
          }

          .value {
            font-size: 24px;
          }

          .wallet {
            .wallet-icon {
              font-size: 1.1rem;
            }

            .wallet-address {
              font-size: 18px;
            }
          }
        }
      }

      .data-item:nth-last-child(2),
      .data-item:nth-last-child(1) {
        grid-column: span 2;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
        padding: 0 20px;

        .label {
          margin-bottom: 8px;
          font-size: 18px;
          text-align: center;
        }

        .wallet {
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          background: rgba(211, 208, 208, 0.38);
          padding: 10px;
          border-radius: 48px;

          .wallet-icon {
            margin-right: 12px;
          }

          .wallet-address {
            flex: 1;
            text-align: left;

            p {
              margin: 0;
              font-size: 16px;
            }
          }

          .wallet-copy {
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .data-item:nth-last-child(1) {
        padding: 0 20px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1198px) {
  .overview-container {
    width: 100%;
    padding: 16px 48px;
  }
}
