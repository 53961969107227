.home_box {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .home_content_box {
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
    .home_content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 1000px;
      margin: 0 auto;
      margin-top: 44px;
      @media (max-width: 600px) {
        width: 80%;
        margin-top: 0;
      }

      .content_left {
        width: 50%;
        margin-top: 40px;
        @media (max-width: 600px) {
          margin-top: 60px;
        }

        .home_title {
          div {
            color: #fff;
            font-size: 52px;
            font-weight: 700;
            line-height: 130%;
            @media (max-width: 600px) {
              font-size: 32px;
            }
          }
          img:nth-child(2) {
            width: 553px;
            height: auto;
            margin-bottom: 16px;
            @media (max-width: 600px) {
              width: 164px;
              margin-bottom: 8px;
            }
          }
          img:nth-child(3) {
            width: 201px;
            height: auto;
            margin-bottom: 16px;
            @media (max-width: 600px) {
              width: 108px;
              margin-bottom: 8px;
            }
          }
        }
        .text_box {
          position: relative;
          display: inline-block;
          line-height: 1.5;
          .home_des {
            color: #fff;
            font-size: 32px;
            font-weight: 500;
            line-height: 110%;
            letter-spacing: 0.32px;

            @media (max-width: 600px) {
              font-size: 14px;
              line-height: 120%;
            }
          }
          .left_img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 50px;
            height: auto;

            img {
              width: 100%;
              height: auto;
              object-fit: contain;
              margin: 8px 0 0 8px;
            }
          }
        }

        .sec_des {
          color: #fff;
          font-size: 14px;
          font-weight: 200;
          line-height: 120%;
          letter-spacing: 0.42px;
          width: 400px;
          margin-top: 24px;
          @media (max-width: 600px) {
            font-size: 12px;
            width: 200px;
            margin-top: 12px;
          }
        }
      }
      .content_right img {
        width: 700px;
        height: auto;
        animation: fish-sway-smooth 8s ease-in-out infinite;
        @media (max-width: 600px) {
          width: 200px;
        }
      }

      @keyframes fish-sway-smooth {
        0% {
          transform: translate(0, 0);
        }
        25% {
          transform: translate(10px, -10px);
        }
        50% {
          transform: translate(0, 10px);
        }
        75% {
          transform: translate(-10px, -10px);
        }
        100% {
          transform: translate(0, 0);
        }
      }
    }
  }
  .home_btn {
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-size: 20px;
    color: #000;
    cursor: pointer;
    margin-top: 53px;
    font-weight: 700;
    @media (max-width: 600px) {
      flex-direction: column;
      font-size: 1.125rem;
      margin-top: 3.75rem;
      text-align: center;
    }
  }

  .nite_btn {
    position: relative;
    background: #fff72b;
    border-radius: 90px;
    padding: 16px 0px;
    // margin-right: 32px;
    border: 1px solid #fef75c;
    width: 240px;
    text-align: center;

    &.disabled {
      opacity: 0.5;
      border: 1px solid #5e5e5e;
      background: #5e5e5e;
    }
    @media (max-width: 600px) {
      width: 13.125rem;
      margin-right: 0;
      margin-bottom: 1.75rem;
      padding: 0.625rem 2.25rem;
    }

    .tooltip {
      position: absolute;
      background: rgba(255, 255, 255, 0.8);
      color: #000000;
      padding: 8px 12px;
      border-radius: 4px;
      font-size: 14px;
      top: -30px;
      left: 90%;
      z-index: 1000;
      transform: translateX(-50%);
      white-space: nowrap;
      z-index: 10;
      animation: fadeIn 0.3s ease;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateX(-50%) translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
      }
    }
  }

  .mini_btn {
    background: #4c5562;
    border: 1px solid #fff;
    border-radius: 90px;
    padding: 16px 0px;
    color: #fef75c;
    width: 240px;
    text-align: center;
    @media (max-width: 600px) {
      width: 13.125rem;
      padding: 0.625rem 2.25rem;
    }
  }
}

@media (max-width: 600px) {
  .page_box {
    .video_bg {
      height: 100vh;
    }
  }
  .content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    width: 100%;

    .home_box {
      width: 100%;
      padding: 6vw;
      padding-top: 16.75vh;
      box-sizing: border-box;

      .home_title {
        font-size: 2rem;
        margin-bottom: 0.5rem;
        width: 70vw;
      }
      .text_box {
        flex-direction: column;

        .left_img {
          width: 9.9375rem;
          height: 3.4375rem;
          margin-bottom: 0.5rem;
        }
        .right_text {
          display: flex;
          flex-direction: column-reverse;
          font-size: 1rem;
          line-height: 1.3;
        }
      }
      .home_des {
        font-size: 1rem;
      }
      .sec_des {
        font-size: 0.75rem;
      }
      .home_btn {
        flex-direction: column;
        font-size: 1.125rem;
        margin-top: 3.75rem;
        text-align: center;

        .nite_btn {
          width: 13.125rem;
          margin-right: 0;
          margin-bottom: 1.75rem;
          padding: 0.625rem 2.25rem;
        }
        .mini_btn {
          width: 13.125rem;
          padding: 0.625rem 2.25rem;
        }
      }
    }
  }
}

@media (max-width: 398px) {
  .content {
    .home_box {
      padding-top: 12.75vh;
    }
  }
}

@media (max-width: 376px) {
  .content {
    .home_box {
      padding-top: 7.75vh;
      .home_btn {
        margin-top: 2.75rem;
        text-align: center;
        .nite_btn {
          margin-bottom: 0.75rem;
        }
      }
    }
  }
}

@media (min-width: 1800px) {
  .content {
    .home_box {
      padding-top: 18.5vh;
    }
  }
}
