.freedom-banner {
  position: relative;
  border-radius: 5px;
  // background: linear-gradient(180deg, #165aa7 0%, rgba(9, 35, 65, 0.44) 100%);
  // box-shadow: 8px 8px 4px 0px rgba(116, 35, 112, 0.39),
  //   8px 8px 4px 0px rgba(144, 51, 244, 0.52) inset;
  // padding: 32px 160px 60px 80px;
  color: #ffffff;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 158px;
  margin-top: 120px;

  .fish-icon {
    position: absolute;
    right: -28vw;
    // left: -12vw;
    width: 455px;
    height: 400px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .info-section {
    text-align: center;

    .banner-title {
      color: #fff;
      font-size: 48px;
      font-weight: 700;
      margin: 0 0 28px;
      text-align: left;
    }
    .balance-wallet {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      // margin-left: 12vw;

      .balance {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 36px;

        .balance-icon {
          width: 58px;
          height: 58px;
          margin-right: 48px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .amount {
          color: #f3e830;
          font-size: 64px;
          font-weight: 700;
          margin: 0;
          margin-right: 16px;
        }
        .balance-unit {
          color: #c5c6c8;
          font-size: 32px;
          font-weight: 500;
        }
      }

      .wallet {
        display: flex;
        align-items: center;
        justify-content: center;

        .wallet-icon {
          width: 58px;
          height: 58px;
          margin-right: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .wallet-address {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 60px;
          background: #fff;
          padding: 18px 37px;
          width: 500px;

          .address {
            color: #242227;
            font-size: 32px;
            font-weight: 400;
          }
          .copy-button {
            background: none;
            border: none;
            cursor: pointer;
            color: #ffffff;
            font-size: 18px;
            margin-left: 10px;

            &:hover {
              color: #00ff9b;
            }
            img {
              width: 39px;
              height: 39px;
            }
          }
        }
      }
    }
  }
}

.footer_burve {
  position: relative;
  text-align: center;
  background: linear-gradient(0deg, #583b69 0%, #100928 100%);
  border-radius: 30px;
  width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 40px;
  @media (max-width: 750px) {
    width: calc( 100% - 20px);
    flex-direction: column;
    margin: 10px;
    margin-bottom: 40px;
  }
  .content{
    width: 800px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
    position: relative;
    @media (max-width: 750px) {
      width: 100%;
      flex-direction: column;
    }
    .logo{
      position: absolute;
    }
    .star-1{
      left: 0;
      bottom: 40px;
      animation: blink 5.5s infinite alternate;
    }
    .star-2{
      right: 40px;
      top: 120px;
      animation: blink 1.5s infinite alternate;

    }
    .star-3{
      left: 40px;
      top: 20px;
      animation: blink 2.5s infinite alternate;

    }
    .star-4{
      right: 60px;
      bottom: 40px;
      animation: blink 4.5s infinite alternate;

    }
    .star-5{
      right: 40px;
      top: 20px;
      animation: blink 3.5s infinite alternate;
    } 
    @media (max-width: 750px) {
      .star-1{
        left: 200px;
        bottom: -140px;
      }
      .star-2{
        right: 40px;
        top: 300px;  
      }
      .star-3{
        left: 40px;
        top: 220px;  
      }
      .star-4{
        right: 60px;
        bottom: -140px;  
      }
      .star-5{
        right: 140px;
        top: 120px;
      } 
    }
    .footer_title {
      font-size: 70px;
      font-weight: 700;
      text-transform: capitalize;
      color: #fff;
      @media (max-width: 750px) {
        font-size: 30px;
      }
    }
    .footer_desc {
      font-size: 24px;
      margin: 0 auto;
      color: #fff;
      font-weight: 600;
      @media (max-width: 750px) {
        font-size: 16px;
        text-align: left;
        padding-left: 40px;
      }
    }
    .footer_logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      column-gap: 40px;
      @media (max-width: 750px) {
        column-gap: 20px;
      }
      img{
        width: 200px;
        @media (max-width: 750px) {
          width: 120px;
          height: auto !important;
        }
      }
      .x-logo{
        width: 40px;
        @media (max-width: 750px) {
          width: 20px;
        }
      }
    }
  }
  .footer_bg {
    img {
      width: 300px;   
    }
    @media (max-width: 750px) {
      display: none;
    }
  }
  .mb_footer_bg {
    display: none;
    img {
      width: 200px;   
    }
    @media (max-width: 750px) {
      display: flex;
    }
  }
}

.copied{
  color: #100928;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 600px) {
  .freedom-banner {
    background: unset;
    box-shadow: unset;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 80px;
    margin-top: 80px;
    height: 70vh;

    .fish-icon {
      position: absolute;
      right: 0;
      // left: 67vw;
      width: 195px;
      height: 167px;
      top: 56vh;
      // transform: scaleX(-1);
    }

    .info-section {
      .banner-title {
        font-size: 32px;
        font-weight: 700;
        line-height: 126.076%;
        text-align: left;
      }

      .balance-wallet {
        border-radius: 5px;
        background: linear-gradient(180deg, #165aa7 0%, rgba(9, 35, 65, 0.44) 100%);
        // box-shadow: 8px 8px 4px 0px rgba(116, 35, 112, 0.39),
        //   8px 8px 4px 0px rgba(144, 51, 244, 0.52) inset;
        padding: 30px 22px;
        margin: 0;

        .balance {
          margin-bottom: 24px;

          .balance-icon {
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }

          .balance-amount {
            font-size: 18px;
          }
          .amount {
            font-size: 26px;
            font-weight: 700;
          }
          .balance-unit {
            font-size: 14px;
          }
        }

        .wallet {
          .wallet-icon {
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }

          .wallet-address {
            width: 200px;
            padding: 8px 16px;
            .address {
              font-size: 12px;
            }

            .copy-button img {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
  .footer_burve {
    text-align: center;

    .footer_bg {
      top: 40%;
      left: 50%;
      height: 50vh;
    }

    .footer_title {
      font-size: 24px;
    }
    .footer_logo {
      gap: 16px;
      margin: 32px 0;
      img:nth-child(1),
      img:nth-child(2) {
        height: 32px;
      }
      img:nth-child(2) {
        margin: 18px 0;
      }
      img:nth-child(3) {
        height: 42px;
      }
    }
    .footer_desc {
      font-size: 16px;
      width: 90%;
      margin-bottom: 60px;
      color: #fff;

      p:nth-child(1) {
        font-weight: 200;
      }
      p:nth-child(2) {
        font-weight: 600;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1198px) {
  .freedom-banner {
    .fish-icon {
      // top: 12vh;
      // left: -8vw;
      right: -200px;
      width: 350px;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}