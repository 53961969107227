.header_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;

  .header_btn {
    display: flex;
    color: #fff;
    p {
      margin-left: 24px;
      cursor: pointer;
    }
  }
}

@media (min-width: 768px) and (max-width: 1198px) {
  .header_box {
    width: 95%;
    padding: 0 32px;
  }
}
