.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  .bg_left_fish {
    position: absolute;
    width: 20vw;
    bottom: 15vh;
    left: -4vw;
    z-index: -1;
    @media (max-width: 600px) {
      width: 50vw;
      bottom: 2vh;
      left: -10vw;
    }
    .left_fish {
      width: 100%;
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    // .video-overlay {
    //   background: linear-gradient(
    //     to bottom,
    //     rgba(24, 38, 61, 0) 10%,
    //     #060c16 100%
    //   );
    // }
    .video-overlay_top {
      background: linear-gradient(
        to top,
        rgba(24, 38, 61, 0) 10%,
        #060c16 100%
      );
    }
    .video-overlay_right {
      background: linear-gradient(
        to right,
        rgba(24, 38, 61, 0) 94%,
        #060c16 100%
      );
    }
  }
  .bg_right_fish {
    position: absolute;
    width: 28vw;
    bottom: -10vh;
    right: -8vh;
    z-index: -1;
    @media (max-width: 600px) {
      width: 50vw;
      bottom: 8vh;
      right: 0vh;
    }

    .right_fish {
      width: 100%;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .video-overlay {
      background: linear-gradient(
        to bottom,
        rgba(24, 38, 61, 0) 10%,
        #060c16 100%
      );
    }
    .video-overlay_top {
      background: linear-gradient(
        to top,
        rgba(24, 38, 61, 0) 10%,
        #060c16 100%
      );
    }
    .video-overlay_left {
      background: linear-gradient(
        to left,
        rgba(24, 38, 61, 0) 60%,
        #060c16 100%
      );
    }
    .video-overlay_right {
      background: linear-gradient(
        to right,
        rgba(24, 38, 61, 0) 60%,
        #060c16 100%
      );
    }
  }
  .footer_box {
    width: 1200px;
    margin: 0 auto;

    .footer_content {
      display: flex;
      justify-content: space-between;

      .footer-links {
        display: flex;
        align-items: center;
        img {
          margin-left: 10px;
        }
      }
    }
  }

  .footer-bar {
    height: 2px;
    background-color: #1b2b44;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .footer-bottom {
    font-size: 16px;
    font-weight: 300;
    line-height: 130%;
    color: #8f9299;
    margin-bottom: 80px;
  }
}
@media (max-width: 600px) {
  .footer {
    width: unset;
    .footer_box {
      width: 100%;
      .footer_content {
        display: flex;
        justify-content: space-between;

        .footer-links {
          display: flex;
          align-items: center;
          img {
            margin-left: 10px;
          }
        }
      }
      .footer_content {
        flex-direction: column;
        align-items: center;
      }

      .footer-links {
        text-align: center;
        justify-content: center;
        margin-top: 24px;
      }
    }
    .footer-bottom {
      text-align: center;
    }
  }
}

@media (min-width: 768px) and (max-width: 1198px) {
  .footer_box {
    width: 100% !important;
    padding: 0 16px !important;
    box-sizing: border-box;
  }
}
